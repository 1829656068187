// export const jira_api = process.env.REACT_APP_JIRA_VARIABLE;

export const jira_api = `https://tft.cloudifytests.io/contactusform`;
export const free_trial_api = `https://tft.cloudifytests.io/send-invite-mail/`;
export const customer_support_api =
  'https://cloudifytests.atlassian.net/servicedesk/customer/portal/3';
export const ns_verification_api =
  'https://tft.cloudifytests.io/accept-invite-mail/';
export const cloudify_video =
  'https://raw.githubusercontent.com/CloudBrowsers/public-assets/main/assets/cloudifytests_video.mp4';
